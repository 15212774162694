import React, { FC } from 'react'

import { Box } from 'components/ui'
import { ReactComponent as Logo } from 'images/logo.svg'

const VerifyWrapper: FC = ({ children }) => (
  <Box
    display="flex"
    flexDirection="column"
    mx="auto"
    width="100vw"
    height="100vh"
    alignItems="center"
    bg="#F7F7FC"
  >
    <Box minHeight="54px" mt="56px">
      <Logo height="34px" width="155px" />
    </Box>

    <Box width="100%" maxWidth="480px" mt="40px" px={0}>
      <Box
        bg="white"
        border="1px solid"
        borderColor="#E1E1F0"
        borderRadius={1}
        boxShadow={3}
        pt="36px"
        pb="36px"
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Box>
    </Box>
  </Box>
)

export default VerifyWrapper
