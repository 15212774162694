import React, { FC, useState, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'

import { getUser } from 'store/modules/auth/selectors'
import { logout } from 'store/modules/auth/actions'
import { Box, Text, Avatar } from 'components/ui'

import Item from 'components/WeddingList/Header/Item'

const SideBar: FC = () => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)

  const { push } = useHistory()

  const onLogout = useCallback(() => dispatch(logout()), [dispatch])

  const handleClickOutside = useCallback(() => setIsOpen(false), [])

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <Box position="relative" height="40px">
        <Box
          position="absolute"
          width="196px"
          bg="white"
          border="1px solid"
          borderColor="gray"
          borderRadius={0}
          boxShadow={0}
          mt={0}
          right="32px"
          top="60px"
          zIndex={2147483647}
        >
          <Item>
            <Text style={{ fontFamily: 'Lato' }}>Profile</Text>
          </Item>

          <Item>
            <Text style={{ fontFamily: 'Lato' }}>Help</Text>
          </Item>

          <Box borderBottom="1px solid" borderColor="gray" />

          <Item onClick={onLogout}>
            <Text style={{ fontFamily: 'Lato' }}>Sign Out</Text>
          </Item>
        </Box>
      </Box>
    </OutsideClickHandler>
  )
}

export default SideBar
