import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Form } from 'react-final-form'
import validate from 'validate.js'
import { mergeAll, path } from 'ramda'

import {
  presenceFieldConstraint,
  passwordConstraint,
  emailConstraint,
} from 'constants/constraints'
import { signup } from 'store/modules/auth/actions'
import { VERIFY, LOGIN } from 'constants/paths'
import { Box, Text, Button } from 'components/ui'
import { FormWrapper } from 'components/Auth'

import SignupForm from './Form'
import './../../fonts/fontFamilies/styles.css'
const Signup: FC = () => {
  const dispatch = useDispatch()
  const { push } = useHistory()

  const onSignup = useCallback(
    async (data) => dispatch(signup(data)),
    [dispatch]
  )
  const handleClickLogin = useCallback(() => push(LOGIN), [push])

  const handleValidate = useCallback(
    (values: object) =>
      validate(
        values,
        mergeAll([
          presenceFieldConstraint('first_name'),
          presenceFieldConstraint('last_name'),
          passwordConstraint(),
          emailConstraint(),
        ])
      ),
    []
  )
  const handleSubmit = useCallback(
    async (values: object, _: any, complete?: (x: any) => void) => {
      try {
        const res = await onSignup(values)

        push({
          pathname: VERIFY,
          state: { user: path(['value', 'payload'], res) },
        })
      } catch (error) {
        if (complete) {
          complete([error.cause?.code])
        }
      }
    },
    [push, onSignup]
  )

  return (
    <FormWrapper>
      <Text
        color="#353B60"
        fontSize="32px"
        lineHeight="40px"
        fontWeight="600"
        textAlign="center"
        fontFamily="Lora"
      >
        Create a new account
      </Text>

      <Box display="flex" alignItems="center" mt="8px" mb="26px">
        <Text
          color="#73737B"
          fontSize="16px"
          lineHeight="20px"
          fontFamily="Lato"
          fontWeight="400"
        >
          Already have an account?
        </Text>
        <Button
          color="primary"
          fontSize="16px"
          fontWeight="700"
          fontFamily="LatoBold"
          lineHeight="24px"
          height="20px"
          onClick={handleClickLogin}
        >
          Sign in
        </Button>
      </Box>

      <Form
        validate={handleValidate}
        onSubmit={handleSubmit}
        component={SignupForm}
      />
    </FormWrapper>
  )
}

export default Signup
