export const getErrorMessage = (errorCode: number) => {
    switch (errorCode) {
        case -51: return "User does not exist. Please register"
        case -52: return "Invalid email or password"
        case -53: return "Please verify your account"
        case -60: return "Unknown error. Please try again"
        case -65: return "Invalid token. Please try again"
        case -70: return "Unknown error. Please try again"
        case -71: return "Please use a valid email address"
        case -72: return "System error. Please try again"
        case -73: return "MySQL error. Please try again"
        case -74: return "User already exists. Please login"
        case -75: return "Invalid token. Please try again"
        case -90: return "Unknown error. Please try again"
        case -91: return "Please use a valid email address"
        case -92: return "User does not exist. Please try a different email"
        case -93: return "System error. Please try again"
        case -99: return "Invalid token. Please try again"
        case -194: return "Your account already exists. Please sign in manually with your email and password"
        default: return "Unknown error. Please try again"
    }
}