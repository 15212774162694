import React, { FC, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { verifyResend } from 'store/modules/auth/actions'
import { Text, Box, Button } from 'components/ui'
import { ReactComponent as Icon } from 'images/emailBox.svg'

type Props = {
  uuid: string
  email: string
}

const EmailSent: FC<Props> = ({ uuid, email }) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const onVerifyResend = useCallback(
    async () => dispatch(verifyResend(uuid)),
    [dispatch, uuid]
  )

  const handleClickResend = useCallback(async () => {
    setIsLoading(true)

    try {
      await onVerifyResend()

      toast('New verification link has been sent successfully')
    } catch (error) {
      toast(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [onVerifyResend])

  return (
    <>
      <Box mb="24px">
        <Icon />
      </Box>

      <Box mb="12px">
        <Text
          fontFamily="Lora"
          fontWeight="600"
          fontSize="32px"
          lineHeight="40px"
          color="#414042"
        >
          Verify your email
        </Text>
      </Box>

      <Box
        mb="24px"
        width="100%"
        maxWidth="352px"
        display="flex"
        justifyContent="center"
      >
        <Text
          fontFamily="Lato"
          fontWeight="400"
          fontSize="16px"
          lineHeight="24px"
          textAlign="center"
          color="#73737B"
        >
          A confirmation email was sent to <br />
          <Text
            fontFamily="Lato"
            fontWeight="400"
            fontSize="16px"
            lineHeight="24px"
            color="#5458F7"
          >
            {email}.&nbsp;
          </Text>
          <Text
            fontFamily="Lato"
            fontWeight="400"
            fontSize="16px"
            lineHeight="24px"
            textAlign="center"
            color="#73737B"
            style={{ maxWidth: '352px' }}
          >
            Open the email to verify your account.
          </Text>
        </Text>
      </Box>

      <Button
        variant="primary"
        style={{
          background: 'linear-gradient(180deg, #A9A2FF 0%, #8379F2 90.62%)',
          borderRadius: '12px',
          width: '352px',
          height: '48px',
          fontFamily: 'Lato',
          fontSize: '16px',
          fontWeight: 700,
          lineHeight: '24px',
        }}
        disabled={isLoading}
        onClick={handleClickResend}
      >
        Continue
      </Button>

      <Box mt="20px" display="inline-flex" alignItems="baseline">
        <Box mr="8px">
          <Text
            fontFamily="Lato"
            fontWeight="400"
            fontSize="16px"
            lineHeight="24px"
            color="#73737B"
          >
            Didn’t get a confirmation?
          </Text>
        </Box>

        <Button
          color="primary"
          fontFamily="Lato"
          fontSize="16px"
          fontWeight="700"
          height="24px"
          disabled={isLoading}
          onClick={handleClickResend}
        >
          Resend email
        </Button>
      </Box>
    </>
  )
}

export default EmailSent
