import React, { FC, useState, useCallback, useEffect, useRef } from 'react'
import ReactSelect, { Props as SelectProps, OptionTypeBase } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { mergeLeft, map, find, propEq, path } from 'ramda'

import Box from '../Box'
import Text from '../Text'
import MobileSelect from './Mobile'

type Props = {
  variant?: 'button' | 'error'
  label?: string
  value?: any
  disableMobile?: boolean
  height?: string | number
  onClick?: (x: any) => VoidFunction
  onChange?: (x: any) => void
}

const Select: FC<SelectProps<OptionTypeBase> & Props> = ({
  variant,
  id,
  name,
  placeholder,
  label,
  components,
  styles,
  options,
  onChange,
  value,
  isCreatable = false,
  disableMobile,
  height = 38,
  onClick,
  ...rest
}) => {
  const selectRef = useRef<any>()
  const [isMobile] = useState(window.innerWidth < 640)

  const handleChange = useCallback(
    (value: string) => {
      if (options && onChange) {
        onChange(
          find(
            (option: any) => propEq('value', parseInt(value, 10), option),
            options
          )
        )
      }
    },
    [onChange, options]
  )

  useEffect(() => {
    selectRef?.current?.blur()
  }, [rest.value])

  if (isMobile && !disableMobile) {
    return (
      <MobileSelect
        placeholder={placeholder}
        value={path(['value'], rest.value)}
        onChange={handleChange}
      >
        {options &&
          map(
            (option: any) => (
              <Box as="option" key={option.value} value={option.value}>
                {option.label}
              </Box>
            ),
            options
          )}
      </MobileSelect>
    )
  }

  return (
    <Box onClick={onClick}>
      {label && (
        <Box mb="4px" ml="4px">
          <Text
            fontFamily="Source Sans Pro"
            fontSize="14px"
            color="#73737B"
            fontWeight="400"
            lineHeight="18px"
          >
            {label}
          </Text>
        </Box>
      )}

      <Box
        as={isCreatable ? CreatableSelect : ReactSelect}
        options={options}
        placeholder={placeholder}
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        components={components}
        isSearchable={variant !== 'button'}
        styles={{
          control: (
            props: any,
            {
              isFocused,
              hasValue,
              isMulti,
            }: { isFocused: boolean; hasValue: boolean; isMulti: boolean }
          ) => {
            let borderColor = '#E8E8ED'
            let borderWidth = 2

            if (variant === 'button') {
              borderWidth = 1
            } else if (props.borderWidth) {
              borderWidth = props.borderWidth
            }

            if (isFocused && variant !== 'button') {
              borderColor = '#3f40f0'
            }

            if (!isFocused && variant === 'error') {
              borderColor = '#f44343'
            }

            return {
              ...props,
              borderColor,
              paddingLeft: '2px',
              borderWidth,
              fontSize: 16,
              borderRadius: 8,
              width: '100%',
              height,
              boxShadow: 'none',
              '&:hover': {
                borderColor,
              },
              ...(variant === 'button'
                ? {
                    '>div:first-of-type': {
                      padding: '0 0 0 16px !important',
                    },
                    '>div:last-of-type': {
                      padding: '0 12px 0 0 !important',
                      '>div': {
                        padding: 0,
                      },
                    },
                  }
                : undefined),
              ...(variant !== 'button' && isMulti && hasValue
                ? {
                    '>div:first-of-type': {
                      padding: isMulti && hasValue ? 2 : undefined,
                      marginTop: -1,
                    },
                  }
                : undefined),
            }
          },
          menu: (provided: any, state: any) => {
            return {
              width: '100%',
              border: '1px solid #E8E8ED',
              backgroundColor: 'white',
              zIndex: 999,
              borderRadius: 8,
              marginBottom: 8,
              marginTop: 8,
            }
          },
          menuList: (base: any) => ({
            ...base,
            cursor: 'pointer',
            borderRadius: 8,
            paddingTop: 0,
            paddingBottom: 0,
            '::-webkit-scrollbar': {
              width: '0',
              height: '0',
            },
            '::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '::-webkit-scrollbar-thumb': {
              background: '#888',
            },
            '::-webkit-scrollbar-thumb:hover': {
              background: '#555',
            },
          }),
          placeholder: (props: object) => ({
            ...props,
            // fontFamily: 'Work Sans',
            color: variant !== 'button' ? '#CBCBD3' : '#1e2027',
            lineHeight: '18px',
            fontSize: '16px',
            fontWeight: 400,
          }),
          indicatorSeparator: (props: object) => ({
            ...props,
            display: 'none',
          }),
          dropdownIndicator: mergeLeft({
            color: '#73737B',
          }),

          singleValue: mergeLeft({
            color: '#414042',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '20px',
          }),
          option: (
            props: { backgroundColor: string },
            { isSelected }: { isSelected: boolean }
          ) => {
            return {
              ...props,
              color: '#414042',
              fontWeight: 400,
              fontSize: '14px',
              height: 40,
              lineHeight: '20px',
              ':hover': {
                backgroundColor: '#F7F7FC',
              },
              padding: '10px 16px',
              backgroundColor: isSelected ? '#FFFFFF' : props.backgroundColor,
            }
          },
          ...styles,
        }}
        ref={selectRef}
        {...rest}
      />
    </Box>
  )
}

export default Select
