import React, { FC, useState, useEffect } from 'react'
import { Container, Row, Col, Card, Button } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import './styleing.css'
import calendarIcon from 'images/calendarIcon.svg'
import pin from 'images/pin.svg'
import bellRedDot from 'images/bellRedDot.svg'
import notificationBell from 'images/notificationBell.svg'
import strokeCheck from 'images/strokeCheck.svg'
import strokeCross from 'images/strokeCross.svg'
import strokeQuestion from 'images/strokeQuestion.svg'
import { list } from 'store/modules/wedding/actions'
import { getWeddings } from 'store/modules/wedding/selectors'
import { Box, Text, Loading } from 'components/ui'
import {
  RemoveWeddingModal,
  ManageAdminsModal,
  Nav,
  NavBar,
  GridItem,
} from 'components/WeddingList'
const Bottomsection: FC = () => {
  const dispatch = useDispatch()
  const weddings = useSelector(getWeddings)
  useEffect(() => {
    dispatch(list())
  }, [])
  console.log(weddings, 'weddings')
  return (
    <Container
      style={{ marginTop: '141px', margin: '141px 50px 0px 50px' }}
      fluid
    >
      <h1 className="my-wedding">My weddings</h1>
      <div className="image-container">
        {weddings.map((wedding, index) => {
          console.log(wedding.spouse1_image[2].link)
          return (
            <div>
              <Card className="wedding-card-info">
                <h1 className="couple-name">{wedding.wedding_name}</h1>
                {/* <Row> */}
                <div className="align-row">
                  <div className="row">
                    <img
                      src={calendarIcon}
                      alt="calendarIcon"
                      className="calendar-icon"
                    />
                    <h1 className="wedding-date">
                      {!isNaN(Date.parse(wedding.wedding_date.split(' ')[0]))
                        ? wedding.wedding_date
                            .split(' ')[0]
                            .split('-')
                            .reverse()
                            .join('-')
                        : 'TBD'}
                    </h1>
                  </div>
                  <div className="row" style={{ marginLeft: '8px' }}>
                    <img src={pin} alt="pin" className="calendar-icon" />
                    <h1 className="wedding-date">
                      {wedding.location_name || 'TBD'}
                    </h1>
                  </div>
                </div>
                <div>
                  <img
                    src={
                      wedding.notifications.n_unread > 0
                        ? bellRedDot
                        : notificationBell
                    }
                    alt="pin"
                    className="bellRedDot"
                  />
                </div>

                <div>
                  <img
                    className="card-profile-image"
                    src={
                      wedding?.wedding_image[4]?.link ||
                      'https://media.istockphoto.com/id/1190043570/photo/happy-wedding-photography-of-bride-and-groom-at-wedding-ceremony-wedding-tradition-sprinkled.jpg?s=612x612&w=0&k=20&c=_aCIW5-iOIiaDdqin_50kvBcbFbIxSULHHamPUILE0c='
                    }
                  />
                  {wedding.admins[0].uuid === wedding.spouse1_uuid ||
                  wedding.spouse2_uuid ? (
                    <button className="couple-status">My wedding</button>
                  ) : (
                    ''
                  )}
                </div>
                <div className="friends-button">
                  <div className="align-center">
                    {' '}
                    <div className="align-center">
                      {wedding.spouse1_image.length > 0 ? (
                        <img
                          className="botton-round-profile"
                          src={wedding.spouse1_image[2].link}
                        />
                      ) : (
                        <h1 className="short-profile-1 text-center">
                          {wedding.spouse1_first_name[0] +
                            wedding.spouse1_last_name[0]}
                        </h1>
                      )}
                    </div>
                    {/* <h1 className="short-profile-1 text-center">AA</h1> */}
                    <div className="align-center">
                      {wedding.spouse2_image.length > 0 ? (
                        <img
                          className="botton-round-profile-2"
                          src={wedding.spouse2_image[2].link}
                        />
                      ) : (
                        <h1 className="short-profile-1 text-center">
                          {wedding.spouse2_first_name[0] +
                            wedding.spouse2_last_name[0]}
                        </h1>
                      )}
                    </div>
                    {/* <h1 className="short-profile-1 short-profile-2 text-center "> */}
                    {wedding.n_actual_guests - 2 > 0 ? (
                      <h1 className="short-profile-1 short-profile-2 text-center ">
                        {wedding.n_actual_guests - 2}+
                      </h1>
                    ) : (
                      0
                    )}
                    {/* </h1> */}
                  </div>
                  {wedding.admins[0].uuid === wedding.spouse1_uuid ||
                  wedding.spouse2_uuid ? (
                    <Row>
                      <img src={strokeCheck} className="align-row-2" />
                      <p className="check">{wedding.n_rsvp.accepted}</p>
                      <img src={strokeCross} />
                      <p className="check">{wedding.n_rsvp.accepted}</p>
                      <img src={strokeQuestion} />
                      <p className="check">{wedding.n_rsvp.not_sure}</p>
                    </Row>
                  ) : (
                    <div className="only-button">
                      {wedding.rsvp_status === '0' ? (
                        <button
                          className="align-row-2 replay-button align-center"
                          style={{
                            color: '#73737B',
                            backgroundColor: '#E8E8ED',
                          }}
                        >
                          Access pending
                        </button>
                      ) : wedding.rsvp_status === '1' ? (
                        <button
                          className="align-row-2 replay-button align-center"
                          style={{
                            color: '#D19D00',
                            backgroundColor: '#FFF6DD',
                          }}
                        >
                          Not sure
                        </button>
                      ) : wedding.rsvp_status === '2' ? (
                        <button
                          className="align-row-2 replay-button align-center"
                          style={{
                            color: '#00A344',
                            backgroundColor: '#E6F6ED',
                          }}
                        >
                          Attending
                        </button>
                      ) : (
                        <button className="align-row-2 replay-button align-center">
                          Need to replay
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </Card>
            </div>
          )
        })}
      </div>
    </Container>
  )
}
export default Bottomsection
