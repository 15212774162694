import React, { FC, useState } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import './styleing.css'
import './../../fonts/fontFamilies/styles.css'
import Logo from 'images/logo.svg'
import Menu from 'images/menu.svg'
import weddingWhite from 'images/weddingWhite.svg'
import weddingGray from 'images/weddingGray.svg'
import searchGray from 'images/searchGray.svg'
import searchWhite from 'images/searchWhite.svg'
import Top from 'images/Top.png'
import { Form } from 'react-final-form'
import Wedding from './Form'
import SideBar from './SideBar'
import Bottomsection from './BottomSection'

const Topsection: FC = () => {
  const [isactive, setIsActive] = useState({
    weddingCode: true,
    searchCouple: false,
  })
  const [isOpenSideBar, setIsOpenSideBar] = useState(false)

  const handleActivatingWedCode: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    setIsActive({ ...isactive, weddingCode: true, searchCouple: false })
  }
  const handleActivatingSearch: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    setIsActive({ ...isactive, weddingCode: false, searchCouple: true })
  }
  const handleSubmit = () => {
    console.log('form')
  }
  const handleSideBar = () => {
    setIsOpenSideBar(!isOpenSideBar)
  }
  return (
    <Container
      style={{ height: '100vh', overflowY: 'scroll', position: 'relative' }}
      fluid
    >
      <Row className="top-header">
        <div className="d-flex flex-row justify-content-end top-header">
          <img src={Logo} alt="logo" className="topsection-log" />
          <div className="right-block">
            <button
              className="create-wedding-button"
              style={{ fontFamily: 'Lato' }}
            >
              Create wedding
            </button>
            <div className="top-sec-profile-name">
              <h1 className="name-displayed" style={{ fontFamily: 'LatoBold' }}>
                PC
              </h1>
            </div>
            <img
              src={Menu}
              alt="logo"
              className="topsection-menu"
              onClick={handleSideBar}
            />
            {isOpenSideBar && <SideBar />}
          </div>
        </div>
      </Row>
      <Row>
        <img src={Top} alt="logo" className="colored-section" />
      </Row>

      <div className="center">
        <Card className="filter-card">
          <h1 className="center finding-couple" style={{ fontFamily: 'Lora' }}>
            Find the couple
          </h1>
          <div className="buttons-section">
            <div>
              <button
                // className="violet-button"
                className={
                  isactive.weddingCode ? 'violet-button' : 'white-button'
                }
                style={{ position: 'relative' }}
                onClick={handleActivatingWedCode}
              >
                <img
                  src={isactive.weddingCode ? weddingWhite : weddingGray}
                  alt="logo"
                  className="weddingWhite"
                  style={{
                    position: 'absolute',
                    left: '30px',
                    top: '11px',
                    height: '20px',
                    width: '20px',
                  }}
                />
                <span style={{ marginLeft: '21px' }}> Enter wedding code </span>
              </button>
            </div>
            <div style={{ marginTop: '-7px' }}>
              <button
                // className="white-button"
                className={
                  !isactive.weddingCode ? 'violet-button' : 'white-button'
                }
                style={{ position: 'relative', marginTop: '6px' }}
                onClick={handleActivatingSearch}
              >
                <img
                  src={isactive.weddingCode ? searchGray : searchWhite}
                  alt="logo"
                  className="weddingWhite"
                  style={{
                    position: 'absolute',
                    left: '45px',
                    top: '11px',
                    width: '20px',
                    height: '20px',
                  }}
                />
                <span style={{ marginLeft: '39px' }}> Search for couple </span>
              </button>
            </div>
          </div>
          <Form
            onSubmit={handleSubmit}
            component={Wedding}
            isactive={isactive}
          />
        </Card>
      </div>

      {/* <Row> */}
      <Bottomsection />
      {/* </Row> */}
    </Container>
  )
}
export default Topsection
