import React, { FC } from 'react'

import { Text, Box, Button } from 'components/ui'
import { ReactComponent as Icon } from 'images/emailBox.svg'
import CustonButton from 'utils/Button'

type Props = {
  isLoading: boolean
  email: string
  onClickResend: () => void
  returnToLogin: () => void
}

const EmailSent: FC<Props> = ({ isLoading, email, onClickResend, returnToLogin }) => (
  <>
    <Icon />

    <Box mt="24px" mb="12px">
      <Text fontFamily="Lora" fontWeight="600" fontSize="32px" lineHeight="40px" color="#353B60">
        Check your email
      </Text>
    </Box>

    <Box
      mb="24px"
      width="100%"
      maxWidth="352px"
      display="flex"
      justifyContent="center"
    >
      <Text fontFamily="Lato" fontWeight="400" fontSize="16px" lineHeight="24px" textAlign="center" color="#73737B">
        A password reset link was sent to the email, <br />
        <Text fontFamily="Lato" fontWeight="400" fontSize="16px" lineHeight="24px" color="#5458F7">
          {email}.&nbsp;
        </Text>
        <Text fontFamily="Lato" fontWeight="400" fontSize="16px" lineHeight="24px" textAlign="center" color="#73737B">
          Open the email and tap the link to reset your password.
        </Text>
      </Text>
    </Box>

    <Box width="80%">
    <CustonButton
        submitting={isLoading}
        buttonAction={'Return to login'}
        setIsSubmitClicked={returnToLogin}
      />
    </Box>

    <Box mt="20px" display="inline-flex" alignItems="baseline">
      <Box mr="0.5rem">
        <Text fontFamily="Lato" fontWeight="400" fontSize="16px" lineHeight="24px" color="#73737B">
          Didn’t get an email?
        </Text>
      </Box>

      <Button
        color="primary"
        fontFamily="Lato"
        fontSize="16px"
        fontWeight="700"
        height="24px"
        disabled={isLoading}
        onClick={onClickResend}
      >
        Resend Email
      </Button>
    </Box>
  </>
)

export default EmailSent
