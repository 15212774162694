import React, { FC, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Form } from 'react-final-form'
import validate from 'validate.js'
import { mergeAll } from 'ramda'

import { Box, Text, Button } from 'components/ui'
import { passwordConstraint, emailConstraint } from 'constants/constraints'
import { login } from 'store/modules/auth/actions'
import { FormWrapper } from 'components/Auth'
import { RESET_PASSWORD, SIGNUP } from 'constants/paths'
import LoginForm from './Form'
import FbGoogle from './FbGoogle'
import './../../fonts/fontFamilies/styles.css'

const Login: FC = () => {
  const dispatch = useDispatch()
  const { push } = useHistory<{ step?: number }>()

  const onLogin = useCallback(async (data) => dispatch(login(data)), [dispatch])

  const handleClickCreateAccount = useCallback(() => push(SIGNUP), [push])

  const handleValidate = useCallback(
    (values: object) =>
      validate(values, mergeAll([passwordConstraint(), emailConstraint()])),
    []
  )

  const handleSubmit = useCallback(
    async (
      values: { email: string; password: string },
      _: any,
      complete?: (x: any) => void
    ) => {
      try {
        await onLogin(values)
      } catch (error) {
        if (complete) {
          complete([error.cause?.code])
        }
      }
    },
    [onLogin]
  )

  return (
    <FormWrapper>
      <Text
        color="#353B60"
        fontSize="32px"
        lineHeight="40px"
        fontWeight="600"
        textAlign="center"
        fontFamily="Lora"
        style={{
          width: '400px',
          height: '40px',
          marginTop: '64px',
          marginBottom: '4px',
        }}
      >
        Welcome back
      </Text>

      <Box display="flex" alignItems="center" mt="4px" mb="24px">
        <Text
          color="#353B60"
          fontSize="16px"
          lineHeight="24px"
          fontFamily="Lato"
          fontWeight="400"
          style={{ color: ' #73737B' }}
        >
          Don’t have an account?
        </Text>

        <Button
          fontFamily="LatoBold"
          lineHeight="24px"
          color="primary"
          fontSize="16px"
          fontWeight="700"
          height="20px"
          onClick={handleClickCreateAccount}
        >
          Register
        </Button>
      </Box>

      <Form
        validate={handleValidate}
        onSubmit={handleSubmit}
        component={LoginForm}
      />

      <Box display="flex" flexDirection="row" width="100%" mt="12px">
        <Box
          borderTop="1px solid"
          borderColor="gray"
          width="33%"
          mt="25px"
          mb="20px"
        />
        <Text
          fontWeight={600}
          fontFamily="Lato"
          fontSize="14px"
          lineHeight="20px"
          style={{
            margin: '16px 9px 8px 9px',
            color: '#73737B',
            fontWeight: '400',
          }}
        >
          Or continue with
        </Text>
        <Box
          borderTop="1px solid"
          borderColor="gray"
          width="36.6%"
          mt="25px"
          mb="20px"
        />
      </Box>
      <FbGoogle />
    </FormWrapper>
  )
}

export default Login
