import axios, { AxiosRequestConfig } from 'axios'
import { stringify } from 'query-string'
import { path, pathOr, equals } from 'ramda'

type Props = {
  contentType?: string
  authToken?: string
  onLogout: VoidFunction
}

const api = ({
  url = '',
  method = 'post',
  contentType = 'application/x-www-form-urlencoded',
  authToken,
  headers = {},
  data = {},
  onLogout,
}: AxiosRequestConfig & Props) => {
  return axios({
    method,
    url:"https://api.hitchhero.com/v1/" + `${url}`,
    // url: `${process.env.REACT_APP_API_URI}/${url}`,
    headers: {
      'Content-Type': contentType,
      AUTHTOKEN: authToken || 'guest_token',
      ...headers,
    },
    data: !['multipart/form-data', 'application/offset+octet-stream'].includes(
      contentType
    )
      ? stringify(data)
      : data,
  }).then((response) => {
    const status = path(['data', 'response', 'status'], response)

    if (equals(status, 'invalid_token')) {
      return onLogout()
    }
    
    if (status !== 'success') {
      throw new Error(
        path(['data', 'response', 'error'], response) || 'Unexpected error', {cause: path(['data', 'response'], response)}
      )
    }

    return {
      ...pathOr({}, ['data', 'response'], response),
      data,
    }
  })
}

export default api
