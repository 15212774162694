import React, { FC } from 'react'

import { FormRenderProps } from 'react-final-form'

import { Box, Button } from 'components/ui'
import { Input } from 'components/form'
import weddingButton from 'images/weddingButton.svg'
import './../../fonts/fontFamilies/styles.css'

interface WeddingProps extends FormRenderProps<{ enteredText: string }> {
  isactive: {
    weddingCode: boolean
    searchCouple: boolean
  }
}
const Form: FC<FormRenderProps<{ enteredText: string; isactive: any }>> = ({
  handleSubmit,
  isactive,
}) => {
  return (
    <Box
      as="form"
      width="480px"
      height="48px"
      onSubmit={handleSubmit}
      style={{ position: 'relative' }}
    >
      <Box mb={2}>
        <Input
          name="enteredText"
          placeholder={
            isactive?.weddingCode
              ? 'Enter the wedding code provided by the hosts'
              : 'Enter the names of the newlyweds '
          }
          height="48px"
          hideErrorMessages
          style={{ margin: '16px 50px 50px 50px' }}
        />
        {isactive?.weddingCode && (
          <button
            type="button"
            style={{
              position: 'absolute',
              transform: 'translateY(-50%)',
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              right: '-12%',
              top: '74%',
              margin: '6px',
            }}
          >
            <img src={weddingButton} alt="search submit" />
          </button>
        )}
      </Box>
    </Box>
  )
}

export default Form
