import React, { FC, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Form, FormRenderProps } from 'react-final-form'
import { useHistory } from 'react-router'
import validate from 'validate.js'
import { toast } from 'react-toastify'
import CustonButton from 'utils/Button'
import { forgotPassword } from 'store/modules/auth/actions'
import { Box, Button, Text } from 'components/ui'
import { Input } from 'components/form'
import { emailConstraint } from 'constants/constraints'
import { LOGIN, SIGNUP } from 'constants/paths'
import EmailSent from './EmailSent'
import { ErrorAlert } from 'components/Auth'
import { getErrorMessage } from 'utils/getErrorMessage'

const ForgotPasswordForm: FC = () => {
  const [isEmailSentVisible, setIsEmailVisible] = useState(false)
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)
  const dispatch = useDispatch()
  const { push } = useHistory()

  const onForgotPassword = useCallback(
    async (email) => dispatch(forgotPassword(email)),
    [dispatch]
  )

  const handleClickLogin = useCallback(() => push(LOGIN), [push])
  const handleValidate = (values: object) => validate(values, emailConstraint())
  const handleSubmit = async (
    { email }: { email: string },
    _: any,
    complete?: (x: any) => void
  ) => {
    try {
      await onForgotPassword(email)

      setIsEmailVisible(true)

      toast('The email has been sent successfully')
    } catch (error) {
      if (complete) {
        complete([error.cause?.code])
      }
    }
  }

  const renderForm = ({
    submitting,
    handleSubmit,
    submitErrors,
    values,
    form,
  }: FormRenderProps<{ email: string }>) => {
    if (isEmailSentVisible) {
      return (
        <EmailSent
          isLoading={submitting}
          email={values.email}
          onClickResend={form.submit}
          returnToLogin={handleClickLogin}
        />
      )
    }

    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        margin="0 auto"
        pl="64px"
        pr="64px"
      >
        <Box mb="12px">
          <Text fontFamily="Lora" fontWeight="600" fontSize="32px" lineHeight="40px" color="#353B60">
            Reset your password
          </Text>
        </Box>

        <Box mb="24px" display="flex" flexDirection="column" width="100%" alignItems="center">
          <Text fontFamily="Lato" fontWeight="400" fontSize="16px" lineHeight="24px" textAlign="center" color="#73737B">
            Enter your email and we’ll send you instructions on how to reset your password.
          </Text>
        </Box>

        <Box as="form" onSubmit={handleSubmit} width="100%">
        {isSubmitClicked && submitErrors && (
            <ErrorAlert messages={getErrorMessage(submitErrors[0])} />
          )}
          {!submitErrors && isSubmitClicked && !values.email && (
            <ErrorAlert messages="Fill in required fields" />
          )}
          <Box mb={24}>
            <label
              style={{
                fontFamily: 'Lato',
                fontSize: '12px',
                marginBottom: '10px',
                fontWeight: 400,
                lineHeight: '18px',
                width: '29px',
                height: '18px',
                color: '#414042',
              }}
            >
              Email
            </label>
            <Input
              name="email"
              placeholder="Enter email"
              height="48px"
              hideErrorMessages
              emailNotFound={isSubmitClicked && submitErrors && values.email}
              style={{ marginTop: '4px' }}
            />
          </Box>
          <CustonButton
            submitting={submitting}
            buttonAction={'Reset password'}
            setIsSubmitClicked={setIsSubmitClicked}
          />
        </Box>
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      margin="0 auto"
    >
      <Form
        validate={handleValidate}
        onSubmit={handleSubmit}
        render={renderForm}
      />
    </Box>
  )
}

export default ForgotPasswordForm
